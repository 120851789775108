@import url(https://fonts.googleapis.com/css2?family=Montserrat&display=swap);
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  background-color: #1a1a1c;
  color: #fff;
}

.coin-app {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 64px;
  color: #fff;
}

.coin-search {
  margin-bottom: 64px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}

.coin-text {
  margin-bottom: 32px;
  text-align: center;
}

.coin-input {
  padding-left: 16px;
  width: 300px;
  height: 50px;
  border-radius: 4px;
  border: none;
  background-image: linear-gradient(
    -225deg,
    #ac32e4 0%,
    #7918f2 48%,
    #4801ff 100%
  );

  color: #e2e2e2;
}

.coin-input::-webkit-input-placeholder {
  color: #e2e2e2;
}

.coin-input:-ms-input-placeholder {
  color: #e2e2e2;
}

.coin-input::placeholder {
  color: #e2e2e2;
}
.coin-container {
    display: flex;
    justify-content: center;
  }
  
  .coin-row {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    height: 80px;
    border-bottom: 1px solid #d7d7d7;
    width: 900px;
  }
  
  .coin {
    display: flex;
    align-items: center;
    padding-right: 24px;
  
    min-width: 300px;
  }
  
  .coin h1 {
    font-size: 16px;
    width: 150px;
  }
  
  .coin img {
    height: 30px;
    width: 30px;
    margin-right: 10px;
  }
  
  .coin-symbol {
    text-transform: uppercase;
  }
  
  .coin-data {
    display: flex;
    text-align: right;
    justify-content: space-between;
    width: 100%;
  }
  
  .coin-price {
    width: 110px;
  }
  
  .coin-volume {
    width: 155px;
  }
  
  .coin-marketcap {
    width: 230px;
  }
  
  .coin-percent {
    width: 100px;
  }
  
  .red {
    color: #f00606;
  }
  
  .green {
    color: #11d811;
  }
